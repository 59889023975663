import { PlanType } from "@/components/editor/stripe/Cell";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { checkUserSubscriptionType } from "@/lib/api-account-utils";
import { runtimeEnv } from "@/lib/runtime-utils";
import { checkEnterpriseCompany } from "@/lib/workspace-util";
import { useGetEnvListQuery, useGetEnvQuery } from "@/redux/api/commonApi";
import dashboardApi from "@/redux/api/dashboardApi";
import { getDashBoardState } from "@/redux/store";
import { dashboardActions } from "@/redux/stores/dashboardStore";
import { projectActions } from "@/redux/stores/projectStore";
import axios from "axios";
import { GetServerSidePropsContext } from "next";
import { getServerSession } from "next-auth";
import { AuthTemplate, nextAuthOptions } from "next-auth-account";
import { useSession } from "next-auth/react";
import { useRouter } from "next/compat/router";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import * as amplitude from "@amplitude/analytics-browser";
import { signIn } from "next-auth/react";
import styled from "styled-components";
import parse from 'url-parse'
import { SIGNIN_REDIRECT_WHITE_LIST } from "@/constants/auth";
import { Trans, useTranslation } from "next-i18next";
import { withServerSideTranslations } from "@/lib/with-server-side-translations";
import formidable from "formidable";

const StyledRoot = styled.div`
  width: 100%;
  /* background-image: url("/images/background/background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; */

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2); 
    z-index: 1000; 
  }

`;

export default function Login(props: any) {
  const { t } = useTranslation("common");

  const { data: env } = useGetEnvListQuery(
    {
      keys: [
        "NEXTAUTH_SERVICE_ID",
        "ACCOUNT_URL",
        "NEXTAUTH_URL",
        "GOOGLE_CLIENT_ID",
        "HOMEPAGE_URL"
      ],
    },
    {
      pollingInterval: 30 * 60 * 1000,
      refetchOnFocus: false,
      refetchOnMountOrArgChange: false,
      refetchOnReconnect: false,
      skip: false,
    }
  );

  const [cookies] = useCookies(["isSignOut"]);
  const router = useRouter();
  const { redirect = "" } = router?.query;
  
  const [redirectPath, setRedirectPath] = useState(redirect || "/dashboard");
  const session = useSession();
  const { openCheckout, home } = router?.query;
  const dispatch = useAppDispatch();
  const [trigger, { data: accountUserInfoData }] =
    dashboardApi.endpoints.getAccountUserInfo.useLazyQuery();

  const queryString = new URLSearchParams(router.query).toString();
  const [pageLoading, setPageLoading] = useState(false);

  const { data: NEXTAUTH_URL } = useGetEnvQuery(
    { key: "NEXTAUTH_URL" },
    {
      pollingInterval: 30 * 60 * 1000,
      refetchOnFocus: false,
      refetchOnMountOrArgChange: false,
      refetchOnReconnect: false,
      skip: false,
    }
  );

  useEffect(() => {
    const handleCredentialResponse = async (response: any) => {
      if (response.credential) {
        try {
          const result = await signIn("google", {
            redirect: false,
            idToken: response.credential,
          });

          if (result?.error) {
            console.error("Google authentication error:", result.error);
          }
        } catch (error) {
          console.error("Error handling credential response:", error);
        }
      }
    };

    const initializeGoogleOneTap = () => {
      if (typeof window !== "undefined") {
        const google = (window as any).google;
        if (google && google.accounts) {
          google.accounts.id.initialize({
            client_id: env?.GOOGLE_CLIENT_ID,
            callback: handleCredentialResponse,
            auto_select: false,
          });
          google.accounts.id.prompt();
        }
      }
    };

    initializeGoogleOneTap();
  }, [env?.GOOGLE_CLIENT_ID]);

  const onClickLogo = () => {
    router?.push(env?.HOMEPAGE_URL);
  };

  const routerPushFunc = (url: string) => {
    router?.push(url);
  };



  const { workspaceParams = '' } = props?.pageProps;

  useEffect(() => {
    let abTestDashboard = "a_dashboard";
    if (openCheckout) {
      setRedirectPath(`/dashboard?${queryString}`); // a
    } else {
      console.log(`hasWorkspaceParams `, workspaceParams);
      if(workspaceParams !== '') {
        const workspaceRedirect = `${NEXTAUTH_URL}/workspace/invite-render?workspaceParams=${encodeURIComponent(workspaceParams)}`;
        setRedirectPath(encodeURIComponent(workspaceRedirect))
      } else {
        setRedirectPath(redirect);
      }
    }
    // homepage에서 로그인 버튼으로 들어온경우 이벤트 제외
    if (home !== "true") {
      window.gtag("set", "user_properties", {
        ab_test_dashboard_240423: abTestDashboard,
      });
    }
  }, [NEXTAUTH_URL, home, openCheckout, queryString, redirect, router?.asPath, workspaceParams]);

  const handleSignInCallback = useCallback(() => {  
    router?.push(redirectPath);
  }, [redirectPath, router]);

  useEffect(() => {
    async function initCheckAccountUserInfo() {
      try {
        trigger();
        if (accountUserInfoData) {
          dispatch(
            dashboardActions.setAccountUserInfo({
              accountUserInfo: accountUserInfoData,
            })
          );
          if (
            accountUserInfoData?.plan
              ?.toLocaleLowerCase()
              ?.includes("enterprise")
          ) {
            if (!accountUserInfoData?.enterprise) {
              await checkEnterpriseCompany({
                userId: session?.data?.user?.id,
                NEXTAUTH_URL,
              })
                .then((result) => {
                  if (result?.success) {
                    dispatch(
                      dashboardActions.setAccountUserInfo({
                        accountUserInfo: {
                          ...accountUserInfoData,
                          enterprise: true,
                        },
                      })
                    );
                  }
                })
                .catch((error) => {
                  console.error(
                    `LoginModal initCheckAccountUserInfo checkEnterpriseCompany error `,
                    { error }
                  );
                });
            }
          }

          if (
            accountUserInfoData?.plan?.toLocaleLowerCase()?.includes("team") ||
            accountUserInfoData?.plan?.toLocaleLowerCase()?.includes("pro") ||
            accountUserInfoData?.plan
              ?.toLocaleLowerCase()
              ?.includes("enterprise") ||
            accountUserInfoData?.enterprise
          ) {
            dispatch(
              dashboardActions.setWorkspaceList({
                workspaceListData: accountUserInfoData?.workspaceList,
                total: accountUserInfoData?.workspaceListTotal,
                inviteWorkspaceLogs: accountUserInfoData?.inviteWorkspaceLogs,
              })
            );
          }
        }
      } catch (error) {
        console.error(`LoginModal initCheckAccountUserInfo error `, { error });
      }
    }

    if (session.status === "authenticated") {
      dispatch(projectActions.updateProjectUserId(session.data.user.email));
      initCheckAccountUserInfo();
    }
  }, [session, dispatch, accountUserInfoData, NEXTAUTH_URL, trigger]);

  useEffect(() => {
    if (!router || !router.isReady) return;

    const callOAuthCallback = sessionStorage.getItem("callOAuthCallback");
    const isCallback = callOAuthCallback === "true";

    const fromGoogle = router.query.from === "google";
    const fromAzure = router.query.from === "microsoft";
    const loggedIn = session.status === "authenticated";
    const callbackCondition =
      (fromGoogle || fromAzure) && isCallback && loggedIn;
    if (callbackCondition) handleSignInCallback();
  }, [handleSignInCallback, redirectPath, router, session.status]);

  useEffect(() => {
    if (session.status === "authenticated") {
      const identifyEvent = new amplitude.Identify();
      const isPaid = accountUserInfoData?.plan !== "none" ? "paid" : "non_paid";

      window.gtag("set", "user_properties", {
        subscription_status: isPaid,
      });

      identifyEvent.set(
        "subscription_status",
        accountUserInfoData?.plan !== "none" ? "paid" : "non_paid"
      );
      amplitude.identify(identifyEvent);
    }
  }, [accountUserInfoData?.plan, session?.data?.user?.id, session.status]);

  return (
    <StyledRoot>
        { pageLoading && 
          <div className="backdrop"/> 
        }
      <AuthTemplate
        onClickLogo={onClickLogo}
        signInCallback={handleSignInCallback}
        redirectPath={redirectPath}
        accountUrl={env?.ACCOUNT_URL!}
        studioUrl={env?.NEXTAUTH_URL!}
        routerPushFunc={routerPushFunc}
        isLoginDefault={true}
        landingPath={""}
        serviceId={env?.NEXTAUTH_SERVICE_ID!}
        pageLoading={pageLoading}
        setPageLoading={setPageLoading}
        t={t}
        Trans={Trans}
        samlSsoLoginResult={props?.pageProps?.samlSsoLoginResult}
      />
    </StyledRoot>
  );
}

export const getServerSideProps = withServerSideTranslations(async function (context) {
  const session = await getServerSession(
    context.req,
    context.res,
    nextAuthOptions
  );
  const { BK_ACCOUNT_URL, BK_STUDIO_URL, NEXTAUTH_URL } = runtimeEnv;
  const query = context.query;
  const {
    step = "",
    plan = "",
    period = "",
    openCheckout = "",
    enterpriseParams,
    redirect = "/dashboard",
  } = query;
  const userId = session?.user?.id || "";

  /**
   * 리디렉트 파라미터 검증 시작
   */
  const redirectUrl = Array.isArray(redirect) ? redirect[0] : redirect
  const parsedRedirect = parse(redirectUrl)

  /**
   * 호스트가 없으면 (상대/절대 경로만 있는 경우) 통과\
   * 호스트가 있는 경우 화이트리스트 검사
   *
   * 기준을 통과하지 못하는 경우 redirect를 /dashboard(기본값)로 변경하여\
   * 다시 현재 페이지로 리디렉트
   */
  if (
    !!parsedRedirect.hostname &&
    !SIGNIN_REDIRECT_WHITE_LIST.some((whiteListHost) =>
      parsedRedirect.hostname.endsWith(whiteListHost),
    )
  ) {
    const parsedResolvedUrl = parse(context.resolvedUrl)

    parsedResolvedUrl.set('query', {
      ...query,
      redirect: '/dashboard',
    })

    return {
      redirect: {
        destination: parsedResolvedUrl.toString(),
        permanent: false,
      },
    }
  }

  let workspaceParams = '';

  if(redirect?.includes('workspaceParams')) {
    const url = new URL(redirect);
    workspaceParams = url.searchParams.get('workspaceParams');
  }
  
  /**
   * enterprise 결제 링크 누르고 들어온 케이스
   */
  if (enterpriseParams) {
    const { data: enterpriseDecryptParams } = await axios.post(
      `${BK_STUDIO_URL}/api/workspace/decrypt-params`,
      {
        params: enterpriseParams,
        key: "enterprisePlan",
      }
    );
    const decryptParams = enterpriseDecryptParams?.data?.decryptParams;

    if (decryptParams?.length > 0) {
      const payer = decryptParams.find((obj) => obj.payer)?.payer;
      const pricingId = decryptParams.find((obj) => obj.pricingId)?.pricingId;
      const quantity = decryptParams.find((obj) => obj.quantity)?.quantity;
      const coupon = decryptParams.find((obj) => obj?.coupon)?.coupon ?? null;

      if (userId === payer) {
        const cancelUrl = NEXTAUTH_URL;
        const successUrl = `${NEXTAUTH_URL}/checkout_redirect?session_id={CHECKOUT_SESSION_ID}&redirect=${NEXTAUTH_URL}`;
        const apiUrl = `${BK_ACCOUNT_URL}/api/studio/v3/subscriptions`;

        const subscriptionRes = await axios.post(apiUrl, {
          userId,
          pricingId,
          success_url: successUrl,
          cancel_url: cancelUrl,
          quantity,
          coupon,
        });

        if (subscriptionRes.data.success) {
          return {
            redirect: {
              destination: subscriptionRes.data.data,
              permanent: false,
            },
          };
        }
      }
    }
  }

  if (session) {
    return { redirect: { destination: redirect, permanent: false } };
  }

  const samlSsoLoginResult = await new Promise<{
    samlSso: string | null;
    SAMLResponse: string | null;
    RelayState: string | null;
    samlSsoError: string | null;
  } | null>((resolve) => {
    formidable().parse(context.req, (err, fields) => {
      if (err) {
        console.error(err);
        resolve(null);
        return;
      }

      try {
        const samlSso =
          (Array.isArray(fields.samlSso)
            ? fields.samlSso[0]
            : fields.samlSso) ?? null;

        if (typeof samlSso !== "string") {
          return resolve(null);
        }

        const SAMLResponse =
          (Array.isArray(fields.SAMLResponse)
            ? fields.SAMLResponse[0]
            : fields.SAMLResponse) ?? null;

        const RelayState =
          (Array.isArray(fields.RelayState)
            ? fields.RelayState[0]
            : fields.RelayState) ?? null;

        const decodedRelayState = !!RelayState
          ? decodeURIComponent(RelayState)
          : null;

        const samlSsoError =
          (Array.isArray(fields.samlSsoError)
            ? fields.samlSsoError[0]
            : fields.samlSsoError) ?? null;

        resolve({
          samlSso,
          SAMLResponse,
          RelayState: decodedRelayState,
          samlSsoError,
        });
      } catch (e) {
        console.error(e);
        resolve(null);
      }
    });
  });

  return { props: {
    workspaceParams,
    samlSsoLoginResult,
  } };
});
